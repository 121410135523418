import React, { useEffect } from 'react';
import './ProgressBar.css';

function ProgressBar(props) {
    // props: 
    // - currentNum: the number of questions alredy answered
    // - questionsNum: the amount of questions in this topic

    // Updates the progress.
    useEffect(() => {
        const progressBar = document.getElementsByClassName("progressBar-progress")[0];
        progressBar.style.width = (props.currentNum / props.questionsNum) * 100 + "%";
    });

    return (
        <div className={"progressBar"}>
            <div className={"progressBar-background"}>
                <div className={"progressBar-progress"}></div>
            </div>
        </div>
    );
}

export default ProgressBar;