import React from 'react';
import './Footer.css';

function Footer(props) {
    // Props: no props.

    return (
        <footer className={"App-footer"}>
            <div></div>
            <div>v2021</div>
        </footer>
    );
}

export default Footer;