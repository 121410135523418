import React, { useContext, useEffect, useRef, useState } from 'react';
import LanguageContext from '../../utils/contexts/LanguageContext.js';
import GameContext from '../../utils/contexts/GameContext.js';
import './Topic.css';
import ProgressBar from '../ProgressBar/ProgressBar.js';
import Question from '../Question/Question.js';
import Results from '../Results/Results.js';
import { Link, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

function Topic(props) {
    // Props:
    // - topicIndex: the chosen topics information
    // - texts: passes Other text to Result
    const topicIndex = props.topicIndex;
    const texts = props.texts;

    // This is used to merge the new router to the router in app component.
    let { path, url } = useRouteMatch();

    // This is used to go the another url address.
    let history = useHistory();

    // The amount of questions answered correctly in this topic.
    let points = useRef(0);

    const gameContext = useContext(GameContext);

    // These are used to set the strings for the elements in this component.
    const languageContext = useContext(LanguageContext);
    //const strings = useRef(fi);

    // Current topic.
    const topic = languageContext.content[topicIndex]; 
    
    // Subtopics of the current topic.
    const subTopicsList = topic.subTopics;

    // The current questions information.
    const [question, setQuestion] = useState(topic.questions[0]);
    // The amount of questions already answered.
    const [questionsAnswered, setQuestionsAnswered] = useState(0);

    // Init topic when user wants to try again.
    const initTopic = () => {
        setQuestion(topic.questions[0]);
        setQuestionsAnswered(0);
        points.current = 0;
    };

    // Save topic when it is completed successfully.
    const saveTopic = () => {
        let isSaved = false;
        // Save the topic as passed only if it is not already saved (no doubles, no updates).
        for (let i = 0; i < gameContext.passedTopics.length; i++) {
            if (gameContext.passedTopics[i].topicId === topic.id) {
                isSaved = true;
            }
        }

        // Add the current topic into the GameContexts passedTopics array
        // if it is not saved there yet.
        if (!isSaved) {
            const obj = {
                topicId: topic.id,
                topicPoints: points.current + "/" + topic.questions.length
            };
    
            gameContext.addPassedTopic(obj);
        }
    };

    // Sets the next question and saves the points to the gameContext.
    const next = () => {
        if (question.id !== topic.questions.length - 1) {
            setQuestion(topic.questions[question.id + 1]);
        } else { // When the last question has been answered.
            history.push("/start");
        }
    };

    const topicListItemContents = useRef([]);

    // Close the topics - hide every topics content.
    const initTopicItems = () => {
        topicListItemContents.current = document.getElementsByClassName("itemContent");
    };

    // Open or close topics - show or hide specific topics' content.
    const toggleSubTopicHeight = (i) => {
        if (topicListItemContents.current[i] === undefined) {
            topicListItemContents.current = document.getElementsByClassName("itemContent");
        }

        if (getComputedStyle(topicListItemContents.current[i]).display === "block") { // If open
            topicListItemContents.current[i].style.display = "none";
        } else { // If closed
            // Set a subtopic as visited when it is opened.
            if (!visitedSubTopicsIndexes.current.includes(i, 0)) { // If not visited.
                visitedSubTopicsIndexes.current.push(i); // Set as visited.
                if (visitedSubTopicsIndexes.current.length === subTopicsList.length) {
                    // The user has opened all topicListItems, and can move to the questionnaire now.
                    setHasReadEverything(true);
                }
            }
            
            topicListItemContents.current[i].style.display = "block";
        }
    };

    // This useEffect will run when this topic component is updated.
    useEffect(() => {
        // Init topic items if they are rendered.
        if (document.getElementsByClassName("topic-list").length !== 0) {
            initTopicItems();
        }
    }, [languageContext]);

    const [hasReadEverything, setHasReadEverything] = useState(false);
    const visitedSubTopicsIndexes = useRef([]);

    return (
        <div className={"topic"}>
            <Switch>
                <Route exact path={path}>
                    <div className={"topic-container"}>
                        <div className={"topic-header"}>
                            <h1>{topic.title}</h1>
                            <p>{topic.info}</p>
                        </div>
                        <div className={"topic-list"}>
                            {
                                subTopicsList.map((subTopic, i) =>
                                    <div className={"topic-list-item"} key={i}>
                                        <button className={"itemTitle"} 
                                            onClick={() => toggleSubTopicHeight(i)}>
                                            {subTopic.title}
                                        </button>
                                        <p className={"itemContent"}>{subTopic.info}</p>
                                    </div>
                                ) 
                            }
                        </div>
                        <div className={"navButtonContainer"}>
                            {
                                hasReadEverything === true ?
                                    // Link to route that is defined later below.
                                    <Link to={`${url}/questions`}> 
                                        <button className={"topic-buttonNext"}>
                                            {texts.current[11].Description/*strings.current.button1*/}
                                        </button>
                                    </Link> 
                                :
                                    // Useless button that indicates the user has not read all subtopics yet.
                                    <button className={"topic-buttonNext"} style={{color: "grey"}}>
                                        {texts.current[11].Description/*Answer questions*/}
                                    </button>
                            }

                            <button className={"topic-buttonCancel"} 
                                onClick={() => history.push("/start")}>
                                {texts.current[12].Description/*strings.current.button2*/}
                            </button>
                        </div>
                    </div> 
                </Route>
                {
                    <Route path={`${path}/questions`}>
                        <div className={"topic-container"}>
                            <div className={"topic-info"}>
                                <h2>
                                    {topic.title}
                                </h2>
                                <ProgressBar questionsNum={topic.questions.length} currentNum={questionsAnswered} />
                            </div>
                            <Question topicIndex={topicIndex} questionIndex={question.id} 
                                questionsAnswered={questionsAnswered} 
                                setQuestionsAnswered={setQuestionsAnswered}
                                points={points} 
                                texts={texts}/>
                            <div className={"navButtonContainer"}>
                                {
                                    // If the user has answered to the current question...
                                    questionsAnswered === question.id + 1 ? 
                                        // If all questions have not been answered yet...
                                        questionsAnswered !== topic.questions.length ? 
                                            <button className={"topic-buttonNext"} 
                                                onClick={next}>
                                                {texts.current[13].Description/*Next strings.current.button3*/}
                                            </button>
                                        :
                                            // Link to route that is defined later below.
                                            <Link to={`${path}/results`}>
                                                <button className={"topic-buttonNext"}>
                                                    {texts.current[13].Description/*strings.current.button3*/}
                                                </button>
                                            </Link>
                                    :
                                        // Disabled button that indicates the user has not read all subtopics yet.
                                        <button className={"topic-buttonNext"} 
                                            style={{color: "grey"}}>
                                            {texts.current[13].Description}
                                        </button>
                                }

                                <button className={"topic-buttonCancel"} 
                                    onClick={() => history.push(history.location.pathname.replace("/questions", ""))}>
                                    {texts.current[12].Description}
                                </button>
                                
                            </div>
                        </div>
                    </Route>
                }
                {
                    // If all questions in this current topic has been answered...
                    questionsAnswered === topic.questions.length ? 
                        <Route path={`${path}/results`}>
                            <Results result={points.current / topic.questions.length * 100}
                                topicName={topic.title}
                                initTopic={initTopic} 
                                saveTopic={saveTopic}
                                texts={texts}/>
                                
                        </Route>
                    :
                        null
                }
            </Switch>
        </div>
    );
}

export default Topic;