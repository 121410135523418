import React, { useRef, useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import LanguageContext from './utils/contexts/LanguageContext.js';
import GameContext from './utils/contexts/GameContext.js';
import TopBar from './components/TopBar/TopBar.js';
import Welcome from './components/Welcome/Welcome.js';
import TopicSelector from './components/TopicSelector/TopicSelector.js';
import Footer from './components/Footer/Footer.js';
import './App.css';
import LanguageSelector from './components/LanguageSelector/LanguageSelector.js';
import axios from 'axios';

function App() {
    // These are the object keys that comes from the database.
    const keys = useRef();
    const texts = useRef(["", "", "", "", "", "",
                          "", "", "", "", "", "",
                          "", "", "", "", "", "", ""]);//opening text, other text, + 17 miscellaneous

    // Gets the topics and questions from the backend and return them.
    const getContentFromDatabase = useCallback(async (lang) => {
        const getContent =  async () => {
            const serverAddress = process.env.REACT_APP_SERVER_ADDRESS || 'localhost';
            const serverPort = process.env.REACT_APP_SERVER_PORT || '8000';
            return await axios.get(`https://${serverAddress}/questions/latest`).then(res => res.data);
        };
        let content = await getContent();
        return getTopicsList(lang, content);
    }, []);

    const getTopicsList = (lang, content) => {
        // Get the keys of the content and send them later in code into the languageSelector.
        keys.current = Object.keys(content.sheets);
        // Here content is already a javascript object, but normal it must be parsed.
        const contentCertainLang = {
            "topics": content.sheets.English,
            "questions":content.sheets.EnglishQ
        };

        // Next we put content variables "topics" and "questions" lists into same the array.
        switch (lang) {
            case "fi":
                contentCertainLang["topics"] = content.sheets.Finnish;
                contentCertainLang["questions"] = content.sheets.FinnishQ;
            break;
            case "en":
                contentCertainLang["topics"] = content.sheets.English;
                contentCertainLang["questions"] = content.sheets.EnglishQ;
            break;
            case "sw":
                contentCertainLang["topics"] = content.sheets.Swedish;
                contentCertainLang["questions"] = content.sheets.SwedishQ;
            break;
            case "ru":
                contentCertainLang["topics"] = content.sheets.Russian;
                contentCertainLang["questions"] = content.sheets.RussianQ;
            break;
            case "la":
                contentCertainLang["topics"] = content.sheets.Latvian;
                contentCertainLang["questions"] = content.sheets.LatvianQ;
            break;
            case "li":
                contentCertainLang["topics"] = content.sheets.Lithuanian;
                contentCertainLang["questions"] = content.sheets.LithuanianQ;
            break;
            case "es":
                contentCertainLang["topics"] = content.sheets.Estonian;
                contentCertainLang["questions"] = content.sheets.EstonianQ;
            break;
            default: break;
        }
        // Create topics into the topicsList and give them subtopics and questions.
        // This is done to merge topics and questions arrays in one to make things 
        // more clear later when using information given here.
        let topicsList = [];
        let currentTopic = "";
        let t = 0;
        let s = 0;
        let q = 0;
        if (contentCertainLang.topics[0] != null)
        {
            texts.current = [];
            texts.current.push(contentCertainLang.topics[0]); // opening text
            //other text + 17 miscellaneous
            for (let i = contentCertainLang.topics.length - 18; i < contentCertainLang.topics.length; i++) {
                texts.current.push(contentCertainLang.topics[i]);
            }
        }

        //start from i=1 to avoid Opening text in content, end and length - 18 to avoid Other text and Miscellaneous texts
        for (let i = 1; i < contentCertainLang.topics.length - 18; i++) {

            if (currentTopic !== contentCertainLang.topics[i].Topic) {
                currentTopic = contentCertainLang.topics[i].Topic;
                s = 0;
                q = 0;
    
                // Create topics.
                topicsList[t] = {
                    id: t,
                    title: currentTopic,
                    info: contentCertainLang.topics[i].Description,
                    subTopics: [],
                    questions: []
                };
    
                t++;
                
                for (let j = 0; j < contentCertainLang.questions.length; j++) {
                    if (t === contentCertainLang.questions[j].Category) {
                        // Create questions for the topic.
                        topicsList[t - 1].questions[q] = {
                            id: q,
                            type: contentCertainLang.questions[j].Type,
                            question: contentCertainLang.questions[j].Question,
                            options: [],
                            answer: contentCertainLang.questions[j].CorrectAnswer,
                            explanation: contentCertainLang.questions[j].Explanation
                        };

                        // Create possible options for the question automatically.
                        let asset = "";
                        for (let k = 0; k < 10; k++) {
                            if (k === 0) asset = "AnswerA";
                            else if (k === 1) asset = "AnswerB";
                            else if (k === 2) asset = "AnswerC";
                            else if (k === 3) asset = "AnswerD";
                            else if (k === 4) asset = "AnswerE";
                            else if (k === 5) asset = "AnswerF";
                            else if (k === 6) asset = "AnswerG";
                            else if (k === 7) asset = "AnswerH";
                            else if (k === 8) asset = "AnswerI";
                            else if (k === 9) asset = "AnswerJ";

                            if (contentCertainLang.questions[j][asset] !== undefined) {
                                topicsList[t - 1].questions[q].options[k] = contentCertainLang.questions[j][asset];
                            }
                        }

                        q++;
                    }
                }
            }

            // Create subtopics for the topic.
            topicsList[t - 1].subTopics[s] = {
                id: s,
                title: contentCertainLang.topics[i].Header,
                info: contentCertainLang.topics[i].Explanation
            };
    
            s++;
        }
        return topicsList;
    }

    const getPlaceHolderTopicsList = (lang) => {
        return getTopicsList(lang, content);
    }
    
    //NOTE: cookies were not working properly in Chrome.
    const setLangInLocalStorage = (lang) => {
        //const cookie = document.cookie;
        // Set cookie to expire in one month.
        
        /*if (cookie !== undefined || cookie !== null || cookie !== "") {
            
            document.cookie = "language=; expires=" + date; //Thu, 01 Jan 2070 00:00:00 UTC;";
        }
        else*/
        localStorage.setItem("language", lang);
        //const date = new Date(new Date().setDate(new Date().getDate() + 30)).toUTCString();
        //document.cookie = "language=" + lang + "; expires=" + date;
    };

    const getLangFromLocalStorage = () => {
        /*const cookie = document.cookie;
        let splitCookie = cookie.split(";");

        splitCookie = splitCookie[0].split("=")[1];

        return splitCookie;*/
        return localStorage.getItem("language");
    };

    // Changes the languageContext.
    const toggleLanguage = useCallback(async (lang) => {
        setLangInLocalStorage(lang);

        setLanguageContext({
            language: lang,
            content: await getContentFromDatabase(lang),
            toggleLanguage: toggleLanguage
        });
    }, [getContentFromDatabase]);

    //placeholder content for first render
    const content = {
                        "_id": "5fb66721e2f7c24424963e98",
                        "sheets": {
                            "Finnish": [],
                            "FinnishQ": [],
                            "English": [],
                            "EnglishQ": [],
                            "Swedish": [],
                            "SwedishQ": [],
                            "Russian": [],
                            "RussianQ": [],
                            "Latvian": [],
                            "LatvianQ": [],
                            "Lithuanian": [],
                            "LithuanianQ": [],
                            "Estonian": [],
                            "EstonianQ": []
                        },
                        "updated": "2020-11-19T12:37:53.687Z",
                        "__v": 0
                    }
    // Information of the current language.
    // Sets the default values for the languageContext.
    const [languageContext, setLanguageContext] = useState({
        language: getLangFromLocalStorage() || "en",
        content: getPlaceHolderTopicsList(getLangFromLocalStorage() || "en"),
        toggleLanguage: toggleLanguage
    });

    useEffect( () => {
        async function fetchRes() {
          const toBeContent = await getContentFromDatabase(getLangFromLocalStorage() || "en");
          setLanguageContext({
              language: getLangFromLocalStorage() || "en",
              content: toBeContent,
              toggleLanguage: toggleLanguage,
          });
        }
        fetchRes();
        
      }, [toggleLanguage, getContentFromDatabase]);
    
    // Mark a topic as passed, so the user remembers 
    // which topics are already completed.
    const addPassedTopic = (obj) => {
        let passedTopics = gameContext.passedTopics;
        passedTopics.push(obj);

        setGameContext({
            passedTopics: passedTopics,
            resetTopics: resetTopics,
            addPassedTopic: addPassedTopic,
            saveToDatabase: saveToDatabase
        })
    };

    const resetTopics = () => {
        setGameContext({
            passedTopics: [],
            resetTopics: resetTopics,
            addPassedTopic: addPassedTopic,
            saveToDatabase: saveToDatabase
        })
        gameContext.passedTopics = [];
    }

    // Save the results into the database.
    const saveToDatabase = (obj) => {
        const serverAddress = process.env.REACT_APP_SERVER_ADDRESS || 'localhost';
        const serverPort = process.env.REACT_APP_SERVER_PORT || '8000';

        axios.post(`http://${serverAddress}:${serverPort}/statistics`, {
            Language: obj.Language,
            Results: obj.Results
        })
    };

    // Information of the games state.
    // Sets the default values for the gameContext.
    const [gameContext, setGameContext] = useState({
        passedTopics: [],
        resetTopics : resetTopics,
        addPassedTopic: addPassedTopic,
        saveToDatabase: saveToDatabase
    });

    return (
        <div className={"App"}>
            <LanguageContext.Provider value={languageContext}>
                <GameContext.Provider value={gameContext}>
                    <Router>
                        <div className={"route-wrapper"}>
                            <TopBar />
                            <div className={"App-content"}>
                                <Switch>
                                    <Route path={"/lang"}>
                                        <LanguageSelector keys={keys} />
                                    </Route>
                                    <Route path={"/welcome"}>
                                        <Welcome texts={texts}/>
                                    </Route>
                                    <Route path={"/start"}>
                                        <TopicSelector texts={texts}/>
                                    </Route>                                   
                                    <Route path={"/"}>
                                        {
                                            languageContext.language === undefined ?
                                                <Redirect to={"/lang"} />
                                                :
                                                <Redirect to={"/welcome"} />
                                        }
                                    </Route>
                                </Switch>
                            </div>
                            <Footer />
                        </div>
                    </Router>
                </GameContext.Provider>
            </LanguageContext.Provider>
        </div>
    );
}

export default App;