import { useEffect } from 'react';

export default (handler, interval) => {

  useEffect(() => {
    if (localStorage.getItem('pointerVisibility') !== null) {
        handler();
    }
    const id = setInterval(handler, interval);
    return () => clearInterval(id);
  }, []);
  return () => handler();
};