import React, { useContext } from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import LanguageContext from '../../utils/contexts/LanguageContext.js';
import GameContext from '../../utils/contexts/GameContext.js';
import Topic from '../Topic/Topic.js';
import './TopicSelector.css';
import checkmark from '../../utils/images/checkmark.png';

function TopicSelector(props) {
    // Props: 
    // - texts: passes Other text to Topic
    const texts = props.texts;

    let { path, url } = useRouteMatch(); // This is used to merge the new router to the router in app component.

    // These are used to set the strings for the elements in this component.
    const languageContext = useContext(LanguageContext);

    const topicsList = [];  // All the topics available.
    for (let i = 0; i < languageContext.content.length; i++) {
        topicsList.push(languageContext.content[i]);
    }

    const gameContext = useContext(GameContext);

    // Checks if the given topic is passed.
    const checkIfPassed = (index) => {
        
        for (let i = 0; i < gameContext.passedTopics.length; i++) {
            if (gameContext.passedTopics[i].topicId === index) {
                return true;
            }
        }
        return false;
    };

    return (
            <div className={"topicSelector"}>
                <Switch>
                    <Route exact path={path}>
                        <h1 className={"topicSelector-title"}>{texts.current[14].Header/*strings.current.title*/}</h1>
                        <p className={"topicSelector-mainText"}>{texts.current[15].Description/*strings.current.mainText*/}</p>
                        <div className={"topicSelector-list"}>
                            {
                                topicsList.map((topic, i) =>
                                    <div key={i}>
                                        <Link className={"link"} to={`${url}/topic-${i}`}>
                                            <button>
                                                {topic.title}
                                                {
                                                    // Here we add a checkmark next to the topics  
                                                    // title if that topic is alredy completed.
                                                    checkIfPassed(i) ?
                                                        <img src={checkmark} className={"checkmark"} alt={"Passed"}></img>
                                                    :
                                                        null
                                                }
                                            </button>
                                        </Link>
                                    </div>
                                ) 
                            }
                        </div>
                        <div></div>
                    </Route>
                    {
                        // Here we create new routes which matches with the topics titles.
                        topicsList.map((topic, i) => 
                            <Route key={i} path={`${path}/topic-${i}`}>
                                <Topic topicIndex={i} texts={texts}/>
                            </Route>)
                    }
                </Switch>
            </div>
    );
}

export default TopicSelector;