import React, { useContext, useEffect, useRef } from 'react';
import LanguageContext from '../../utils/contexts/LanguageContext.js';
import constants from '../../utils/constants/constants.js';
import { useHistory } from 'react-router-dom';
import './Results.css';
import GameContext from '../../utils/contexts/GameContext.js';

function Results(props) {
    // Props:
    // - result: the final result on the current topic
    // - topicName: the title/name of the current topic
    // - initTopic: init important values to retry the game
    // - saveTopic: save the game to the GameContext
    // - texts: contains "Other text"  from topicslist, shown when all topics are passed, and misc texts

    const result = props.result;
    const topicName = props.topicName;
    const initTopic = props.initTopic;
    const saveTopic = props.saveTopic;
    const texts = props.texts;

    // These variables are used to set the strings to the elements in this component.
    const languageContext = useContext(LanguageContext);
    //const strings = useRef(fi);

    const history = useHistory();

    let finalWord = "";

    // Check if the result is enough to pass the topic.
    if (result >= constants.MIN_GRADE_TO_PASS) {
        finalWord = texts.current[6].Header/*strings.current.passed*/;
    } else {
        finalWord = texts.current[7].Header;/*strings.current.failed*/;
    }

    const gameContext = useContext(GameContext);

    // Finish the topic and go back to "/start" endpoint.
    const finish = () => {
        if (gameContext.passedTopics.length !== languageContext.content.length) {
            history.push("/start");
        } else {
            document.getElementsByClassName("gameEndInfo")[0].style.display = "flex";
        }
    };

    // Try the questionnaire of this topic again.
    const retry = () => {
        initTopic();
        history.push(history.location.pathname.replace("/results", ""));
    };

    const isSaved = useRef(false);

    useEffect(() => {
        // Save the game into the GameContext if the questionnaire of this topic is passed.
        if (result >= constants.MIN_GRADE_TO_PASS && isSaved.current === false) {
            saveTopic();
            isSaved.current = true;
        }
        //only save to database if result of a topic >min grade to pass. Prevents non-passing score submits
        //after completing the game
        if (result >= constants.MIN_GRADE_TO_PASS) {
            // Save the results in the GameContext into the database when the game has ended.
            if (gameContext.passedTopics.length === languageContext.content.length) {
                let resultsByCategory = {
                    Language: languageContext.language,
                    Results: []
                };

                // Create a new array which will be sorted by the category.
                // Category/topic with the lowest index should be first.
                for (let i = 0; i < gameContext.passedTopics.length; i++) {
                    for (let j = 0; j < gameContext.passedTopics.length; j++) {
                        if (gameContext.passedTopics[j].topicId === i) {
                            let resultObj = {
                                Category: gameContext.passedTopics[j].topicId + 1,
                                Points: gameContext.passedTopics[j].topicPoints
                            };
                            resultsByCategory.Results.push(resultObj);
                        }
                    }
                }
                gameContext.saveToDatabase(resultsByCategory);
            }       
        }
    }, []);

    return (
        <div className={"results"}>
            <h2>{topicName}</h2>
            <p className={"results-explanation"}>{texts.current[4].Description/*You have answered every question! */}</p>
            <div className={"result"}>
                <h1>{result + "% " + texts.current[2].Description/*strings.current.correct*/}</h1>
                <p>{finalWord}</p>
                <div className={"results-buttons"}>
                    <button className="button_result" onClick={retry}>{texts.current[8].Description}</button> {/* Start again */}
                    <button className="button_result" onClick={finish}>{texts.current[18].Description}</button> {/* Back to home page */}
                </div>
            </div>
            <div></div>
            <div className={"gameEndInfo"}>
                <img id={"logo"} src={process.env.PUBLIC_URL + "/FazerLogo.png"} alt={"logo"} />
                <h1>{/*Game completed!*/texts.current[10].Header}</h1>
                <p>{texts.current[1].Explanation/*Other text, shown when all topics are passed*/}</p>
                <button onClick={() => {
                    gameContext.resetTopics();
                    document.getElementsByClassName("gameEndInfo")[0].style.display = "none";
                    history.push("/welcome");}}>{texts.current[9].Description /*Finish*/ }</button>
            </div>
        </div>
    );
}

export default Results;