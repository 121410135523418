import React from 'react';
import { useHistory } from 'react-router-dom';
import './Welcome.css';

function Welcome(props) {
    // Props: .
    // - texts: Opening text, other text and miscellaneous texts given in uploaded excel file
    const texts = props.texts;

    // This is used to go to another url address.
    let history = useHistory();
    
    return (
        <div className="welcome">
            <div>
                {/* <Link to={"/"}> */}
                    <img id={"logo"} src={process.env.PUBLIC_URL + "/FazerLogo.png"} alt={"logo"} />
                {/* </Link> */}
            </div>
            <h1>{/*title*/texts.current[16].Description}</h1>
            <div>
                <p>
                    {texts.current[0].Description/*Opening text*/}
                </p>
                <button onClick={() => history.push("/start")}>{/*start*/texts.current[17].Description}</button>
            </div>
            <div></div>
        </div>
    );
}

export default Welcome;