import React from 'react';
import { useHistory } from 'react-router-dom';
import LanguageContext from '../../utils/contexts/LanguageContext.js';
import './LanguageSelector.css';
import fi from '../../utils/images/lang_select/fi.png';
import gb from '../../utils/images/lang_select/gb.png';
import se from '../../utils/images/lang_select/se.png';
import ru from '../../utils/images/lang_select/ru.png';
import lv from '../../utils/images/lang_select/lv.png';
import lt from '../../utils/images/lang_select/lt.png';
import ee from '../../utils/images/lang_select/ee.png';


function LanguageSelector(props) {
    // Props:
    // - allContent: all content from the database

    const strings = ["Suomi", "English", "Svenska", "Pусский", "Latviešu", "Lietuvių", "Eesti"];

    // Get every other key from the content to get the all the languages.
    // Because database returns keys "Finnish", "FinnishQ", "English", "EnglishQ" etc.
    // So after the loop we get fi, en, etc.
    const allLanguages = [];

    for (let i = 0; i < props.keys.current.length; i++) {
        if (i % 2 === 0) {
            allLanguages.push(props.keys.current[i]);
        }
    }

    let history = useHistory();

    const goBack = () => {
        history.push("/welcome");
    };

    const getCountryFlag = (lang) => {
        let flag = gb;
        switch (lang) {
            case "Finnish": flag = fi; break;
            case "English": flag = gb; break;
            case "Swedish": flag = se; break;
            case "Russian": flag = ru; break;
            case "Latvian": flag = lv; break;
            case "Lithuanian": flag = lt; break;
            case "Estonian": flag = ee; break;
            default: flag = gb; break;
        }
        return flag;        
    }

    return (
        <LanguageContext.Consumer>
            {({toggleLanguage}) => (
                <div className={"languageSelector"}>
                    {allLanguages.map((language, i) => 
                        <button onClick={() => { toggleLanguage(language.substring(0, 2).toLowerCase()); goBack() }} key={i}>
                            <img
                                src={getCountryFlag(language)}
                                className="country-flag-lang-selector"
                                width="32"
                                height="24"
                                alt="countryflag">
                            </img>

                            <div className="text-wrapper">{strings[i]}</div>
                        </button>)}
                </div>
            )}
        </LanguageContext.Consumer>
    );
}

export default LanguageSelector;