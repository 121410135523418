import React, { useContext } from 'react';
import LanguageContext from '../../utils/contexts/LanguageContext.js';
import './TopBar.css';
import fi from '../../utils/images/top_bar/fi.png';
import gb from '../../utils/images/top_bar/gb.png';
import se from '../../utils/images/top_bar/se.png';
import ru from '../../utils/images/top_bar/ru.png';
import lv from '../../utils/images/top_bar/lv.png';
import lt from '../../utils/images/top_bar/lt.png';
import ee from '../../utils/images/top_bar/ee.png';
import { useHistory } from 'react-router-dom';
import pointer from '../../utils/images/language_pointer.png';
import useInterval from '../../useInterval.js';

/**
 * 
 * @param {*} props 
 */
function TopBar(props) {
    // Props: no props.

    let history = useHistory();

    // These are used to set the strings on the elements in this component.
    const languageContext = useContext(LanguageContext);
    
    const getCountryFlag = (lang) => {
        let flag = gb;
        switch (lang) {
            case "fi": flag = fi; break;
            case "en": flag = gb; break;
            case "sw": flag = se; break;
            case "ru": flag = ru; break;
            case "la": flag = lv; break;
            case "li": flag = lt; break;
            case "es": flag = ee; break;
            default: flag = gb; break;
        }
        return flag;        
    }
    
    const hidePointer = () => {
        document.getElementsByClassName("pointer")[0].style.display = "none";
    }

    const stopInterval = useInterval(hidePointer, 8000);
    return (
        <header id="topBar" className="topBar">
            <div></div>
            <div className="pointer">
                <img  src={pointer} width="25" height="25" alt="pointer"></img>
            </div>
            
            <button className={"langButton"} onClick={() =>  {
                stopInterval();
                localStorage.setItem('pointerVisibility', "none");
                history.push("/lang")
            }}>         
            <img
                src={getCountryFlag(languageContext.language)}               
                width="40"
                height="30"
                alt="countryflag">
            </img>
            </button>
        </header>
    );
}

export default TopBar;
