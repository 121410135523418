import React, { useContext, useEffect, useRef } from 'react';
import LanguageContext from '../../utils/contexts/LanguageContext.js';
import './Question.css';

/**
 * 
 * @param {*} props 
 */
function Question(props) {
    // Props:
    // - topicIndex: the index of the current topic
    // - questionIndex: the index of the current question
    // - questionsAnswered: the amount of questions already answered
    // - setQuestionsAnswered: the function to set the amount of questions already answered
    // - setCookie: stores the questionsAnswered as a cookie
    // - points: the amount of questions the user has answered correctly in this topic
    // - texts: correct/wrong, lock answers from Misc in uploaded excel

    const topicIndex = props.topicIndex;
    const questionIndex = props.questionIndex;
    const questionsAnswered = props.questionsAnswered; // The amount of questions already answered.
    const setQuestionsAnswered = props.setQuestionsAnswered; // Sets the questionsAnswered's value.
    const points = props.points;
    const texts = props.texts;
    const answerToCurrentQuestion = useRef([]); // The answer/answers the user has chosen.

    // These variables are used to set the strings to the elements in this component.
    const languageContext = useContext(LanguageContext);

    // The information about the current question.
    const questionInfo = languageContext.content[topicIndex].questions[questionIndex];

    const chooseAnswer = (i) => {
        let letter = "";

        if (i === 0) letter = "a";
        else if (i === 1) letter = "b";
        else if (i === 2) letter = "c";
        else if (i === 3) letter = "d";
        else if (i === 4) letter = "e";
        else if (i === 5) letter = "f";
        else if (i === 6) letter = "g";
        else if (i === 7) letter = "h";
        else if (i === 8) letter = "i";
        else if (i === 9) letter = "j";

        // If the current question has not been answered yet...
        if (questionsAnswered < questionInfo.id + 1) {
            const buttons = document.getElementsByClassName("buttonAnswer");
            const buttonLockAnswer = document.getElementsByClassName("buttonLockAnswers")[0];
            let wasChosenAlready = false;

            for (let k = 0; k < answerToCurrentQuestion.current.length; k++) {
                // If the now chosen answer is already chosen, 
                // then remove it from the users answer list.
                if (answerToCurrentQuestion.current[k] === letter) {
                    buttons[i].style.backgroundColor = "lightgrey";
                    answerToCurrentQuestion.current.splice(k, 1);
                    wasChosenAlready = true;
                    break;
                }
            }

            // If the now chosen answer is not already chosen,
            // then add it to the users answer list.
            if (wasChosenAlready === false) {
                buttons[i].style.backgroundColor = "darkgrey";
                answerToCurrentQuestion.current.push(letter);
            }

            // If the user has chosen something for the answer, 
            // then it is possible to lock the answer, which is
            // indicated with text color of the button.
            if (answerToCurrentQuestion.current.length > 0) buttonLockAnswer.style.color = "black";
            else buttonLockAnswer.style.color = "grey";
        }
    };

    // Chooses the answer and tells the user if it's correct or not.
    const lockAnswer = (i) => {
        const buttons = document.getElementsByClassName("buttonAnswer");

        if (i !== undefined) { // If yes-or-no question.
            let letter = "";

            if (i === 0) letter = "a";
            else if (i === 1) letter = "b";
            else if (i === 2) letter = "c";
            else if (i === 3) letter = "d";
            else if (i === 4) letter = "e";
            else if (i === 5) letter = "f";
            else if (i === 6) letter = "g";
            else if (i === 7) letter = "h";
            else if (i === 8) letter = "i";
            else if (i === 9) letter = "j";

            answerToCurrentQuestion.current.push(letter);

            if (questionsAnswered < questionInfo.id + 1) buttons[i].style.backgroundColor = "darkgrey";
        } else { // If multi-choice question.
            document.getElementsByClassName("buttonLockAnswers")[0].style.color = "grey";
        }
        
        // If the user has chosen the answer but has not locked it before.
        if (answerToCurrentQuestion.current.length > 0 && questionsAnswered < questionInfo.id + 1) {
            const questionExplanation = document.getElementsByClassName("question-explanation")[0];
            const questionExplanationOutcome = document.getElementsByClassName("question-explanation-outcome")[0];
            let alreadyCheckedAnswers = [];
            let answer;
            let correctAnswers;
            let isCorrect = true;

            // If the answers type is a String, split it by either "," or " " character.
            if (typeof(questionInfo.answer) === "string") correctAnswers = questionInfo.answer.split(/[, ]/);
            
            // Set all buttons text color lightgrey to indicate inactiveness.
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].style.color = "grey";
            }

            // Check the correct and incorrect answers.
            // We could define here which buttons should indicate 
            // the users answer being correct and incorrect.
            for (let i = 0; i < answerToCurrentQuestion.current.length; i++) {
                answer = answerToCurrentQuestion.current[i];
                
                // We could define here which of the users 
                //answers were correct and mark them as such.
                for (let j = 0; j < correctAnswers.length; j++) {
                    if (correctAnswers[j] === answer) {
                        alreadyCheckedAnswers.push(answer);
                    }
                }

                // We could define here which of the users 
                // answers were incorrect and mark them as such.
                for (let j = 0; j < correctAnswers.length; j++) {
                    if (!alreadyCheckedAnswers.includes(answer)) {
                        isCorrect = false;
                    }
                }
            }

            // We could define here which of the correct answers 
            // were not chosen by user and mark them as such.
            for (let j = 0; j < correctAnswers.length; j++) {
                if (!alreadyCheckedAnswers.includes(correctAnswers[j])) {
                    isCorrect = false;
                }
            }

            if (isCorrect) { // If the locked answer is correct.
                questionExplanationOutcome.innerHTML = texts.current[2].Description/*strings.current.correct*/;
                points.current = props.points.current + 1;
            } else  { // If the locked answer is incorrect.
                questionExplanationOutcome.innerHTML = texts.current[3].Description/*strings.current.wrong*/;
            }

            questionExplanation.style.visibility = "visible";

            setQuestionsAnswered(questionsAnswered + 1); // Set this to change the progress of the progressBar.
        }
    };

    // Hide the question explanation - question explanation becomes visible when
    // the current question is answered and and the question is locked.
    const hide = () => {
        const questionExplanation = document.getElementsByClassName("question-explanation")[0];
        questionExplanation.style.visibility = "hidden";
    };

    // Init this Question component every time the question changes,
    // so the correct answer won't show automatically and the answerToCurrentQuestion list is emptied.
    useEffect(() => {
        const questionExplanation = document.getElementsByClassName("question-explanation")[0];
        questionExplanation.style.visibility = "hidden";

        answerToCurrentQuestion.current = [];

        const answerButtons = document.querySelectorAll("div.question button.buttonAnswer");
        for (let i = 0; i < answerButtons.length; i++) {
            answerButtons[i].style.backgroundColor = "lightgrey";
            answerButtons[i].style.color = "black";
        }
    }, [questionInfo]);
    
    return (
        <div className={"question"}>
            <h1>{questionInfo.question}</h1>
            {
                questionInfo.type === 1 ? // If the question is a basic yes-or-no-question.
                    <div className={"answerButtonContainer"}>
                        {
                            questionInfo.options.map((option, i) => 
                                option !== " " ?
                                    <button className={"buttonAnswer"} 
                                        key={i} 
                                        onClick={() => lockAnswer(i)}>
                                        {option}
                                    </button>
                                :
                                    null
                            )
                        }
                    </div> 
                :
                    questionInfo.type === 2 ? // If the question is a multi-choice question.
                        <div className={"answerButtonContainer"}>
                            {
                                questionInfo.options.map((option, i) => 
                                    option !== " " ?
                                        <button className={"buttonAnswer"} 
                                            key={i} 
                                            onClick={() => chooseAnswer(i)}>
                                            {option}
                                        </button>
                                    :
                                        null
                                ) 
                            }
                            <div>
                                <button className={"buttonLockAnswers"} 
                                    onClick={() => lockAnswer()} 
                                    style={{color: "grey"}}>
                                    {texts.current[5].Description/*strings.current.button*/}
                                </button>
                            </div>
                        </div>
                    :
                        null
                        
            }
            <div className={"question-explanation"}>
                <button className={"exitButton"} onClick={() => hide()}>X</button>
                <h2 className={"question-explanation-outcome"}>Placeholder</h2>
                <p>{questionInfo.explanation}</p>
            </div>
        </div>
    );
}

export default Question;